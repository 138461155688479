/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import axios from 'axios'
import {
  fetchAccessToken,
  handleErrorResponse,
  handleErrorNoResponse,
} from '../../api'
import { getApiServer, Aborted, isCancel } from '../../api/common'
import appConfig from '../../config/appConfig'
const { apiUri } = appConfig
const apiServer = axios.create({
  baseURL: `${apiUri}`,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
})

const billingAPI = {
  fetchPlans: async (ops) => {
    const { signal } = ops

    try {
      const response = await apiServer('/plans', { signal })
      return response.data
    } catch (err) {
      if (axios.isCancel(err)) {
        throw new Aborted('Call aborted', err)
      } else {
        console.error(err)
        throw Error('Unable to fetch billing plans')
      }
    }
  },
  fetchBillingList: async (billingParams, ops) => {
    //console.log('fetchBillingList ', billingParams, ops)
    const { signal } = ops
    var apiUrl = apiUri + billingParams.paramKey // var apiUrl = apiUri + '/pay/customers'

    try {
      // 서비스 호출
      const authorizationToken = await fetchAccessToken()
      const response = await apiServer.get(`${apiUrl}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authorizationToken,
        },
      })
      // console.log(response.data)
      return response.data
    } catch (err) {
      if (axios.isCancel(err)) {
        throw new Aborted('Call aborted', err)
      } else {
        console.error(err)
        throw Error('Unable to fetch billing plans')
      }
    }
  },
  isCancel: isCancel,
}

export default billingAPI
