/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from '@reduxjs/toolkit'

import { normalize, schema } from 'normalizr'

import billingAPI from '../api'
import thunk from 'redux-thunk'

const planSchema = new schema.Entity(
  'billingPlans',
  {},
  { idAttribute: 'planId' }
)
const planListSchema = [planSchema]

const planAdapter = createEntityAdapter({
  selectId: (entity) => entity.planId,
})

const billingSchema = new schema.Entity(
  'billingList',
  {},
  { idAttribute: 'id' }
)
const billingListSchema = [billingSchema]

const billingCustomerAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
})

export const fetchPlans = createAsyncThunk(
  'billingPlans/fetchAll',
  async (...[, thunkAPI]) => {
    const { signal } = thunkAPI
    try {
      const response = await billingAPI.fetchPlans({ signal })
      const normalized = normalize(response, planListSchema)
      return normalized.entities
    } catch (err) {
      if (billingAPI.isCancel(err)) {
        return
      } else {
        console.error(err)
        return thunkAPI.rejectWithValue(err.message)
      }
    }
  }
)

export const fetchBillingList = createAsyncThunk(
  'billingPlans/fetchBillingList',
  async (billingParams, thunkAPI) => {
    const { signal } = thunkAPI
    try {
      const response = await billingAPI.fetchBillingList(billingParams, {
        signal,
      })
      //console.log(response)
      const normalized = normalize(
        response.content || response.invoiceList,
        billingListSchema
      )

      return normalized.entities
    } catch (err) {
      if (billingAPI.isCancel(err)) {
        console.error(err)
        return
      } else {
        console.error(err)
        return thunkAPI.rejectWithValue(err.message)
      }
    }
  }
)

const initialState = billingCustomerAdapter.getInitialState({
  loading: 'idle',
  error: undefined,
})
const PLAN_SLICE_NAME = 'billingPlans'
const planSlice = createSlice({
  name: PLAN_SLICE_NAME,
  initialState,
  reducers: {
    dismissError(state, error) {
      state.error = null
      return state
    },
  },
  extraReducers: {
    RESET: (state) => {
      return initialState
    },
    [fetchPlans.fulfilled]: (state, action) => {
      if (action.payload !== undefined) {
        const { billingPlans } = action.payload
        planAdapter.setAll(state, billingPlans ?? [])
      }
      state.loading = 'idle'
      state.error = null
      return state
    },
    [fetchPlans.pending]: (state) => {
      state.loading = 'pending'
      state.error = null
      return state
    },
    [fetchPlans.rejected]: (state, action) => {
      state.loading = 'idle'
      state.error = action.payload

      return state
    },
    [fetchBillingList.fulfilled]: (state, action) => {
      //console.log('fetchBillingList.fulfilled', action)
      if (action.payload !== undefined) {
        billingCustomerAdapter.setAll(state, action.payload ?? []) //billingCustomerAdapter.upsertMany(state, action.payload ?? [])
      }
      state.loading = 'idle'
      state.error = null
      return state
    },
    [fetchBillingList.pending]: (state) => {
      state.loading = 'pending'
      state.error = null
      return state
    },
    [fetchBillingList.rejected]: (state, action) => {
      state.loading = 'idle'
      state.error = action.payload

      return state
    },
  },
})

export const selectLoading = (state) => state.billingPlans.loading
export const selectError = (state) =>
  state.error ? state.billingPlans.error : null
export const selectErrorName = (state) =>
  state.billingPlans ? state.billingPlans.errorName : null

const { actions, reducer } = planSlice

export default reducer

export const { fetchAll, dismissError } = actions

export const selectPlanLoading = (state) => state[PLAN_SLICE_NAME].loading
export const selectPlanError = (state) => state[PLAN_SLICE_NAME].error

export const billingPlans = planSlice.reducer

export const {
  selectAll: selectAllPlans,
  selectById: selectPlanById,
} = planAdapter.getSelectors((state) => state[PLAN_SLICE_NAME])

const BILLING_SLICE_NAME = 'billingPlans'
export const {
  selectAll: selectAllBillingList,
  selectById: selectBillingCustomerById,
} = billingCustomerAdapter.getSelectors((state) => state[BILLING_SLICE_NAME]) // } = billingCustomerAdapter.getSelectors((state) => state[PLAN_SLICE_NAME])
